.Comment-section {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-top: 0.7em;
  min-height: 30em;
}

.Comment-section .User-notes-nothing {
  min-height: 15em;
}

.Comment-section .Module-heading:first-child {
  font-size: 1.25em;
}

.Comment-section-add {
  margin-bottom: 1.4em;
}

.Comment-section-add-placeholder {
  width: 100%;
  background: none;
  padding: 0.3em 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid var(--super-dark-bg-color);
  font-size: 0.9em;
}

.Comment-section-add-placeholder:focus {
  outline: none;
}

.Comment-section-add .Askd-form .Askd-button[type="button"] {
  margin: 0.4em 0.4em 0 0;
  border: none;
  box-shadow: none;
}

.Comment-section-add .Askd-form .Askd-button:first-child {
  background-color: var(--main-content-color);
  color: var(--main-text-color);
  min-width: 5em;
}

.Comment-section-add .Askd-form .Askd-button:first-child:hover,
.Comment-section-reply-button:hover {
  background-color: var(--main-bg-color);
}

.Comment-section-add .Askd-form .Askd-button:last-child {
  min-width: 6em;
}

.Comment-section-add .Askd-text-editor {
  margin: 0;
}

.Comment-section-add .Askd-text-editor .Askd-text-editor-text {
  min-height: 10em;
}

.Comment-section-comments {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Comment-section-replies {
  margin: 0;
  padding-left: calc(min(2.5em, 8%));
  list-style-type: none;
}

.Comment-section-root-comment {
  margin-top: 0.9em;
}

.Comment-section-root-comment:first-child {
  margin-top: 0;
}

.Comment-section-reply-comment {
  margin-top: 0.6em;
}

.Comment-section-comments .Comment-section-comment {
  position: relative;
  padding: 0.6em 0.8em 0.5em 0.8em;
}

.Comment-section-comments .Comment-section-own-comment {
  box-shadow: var(--highlighted-content-shadow);
}

.Comment-section-reply-username {
  position: relative;
  bottom: 0.5em;
  font-size: 0.8em;
  float: right;
}

.Comment-section-reply-username .icon-reply {
  margin-left: 0.2em;
  color: var(--bright-heading-color);
}

.Comment-section-avatar {
  position: absolute;
  width: 3em;
  height: 3em;
  border-radius: 2px;
  object-fit: cover;
}

.Comment-section-comment-body {
  margin-left: 3.9em;
}

.Comment-section-reply-form .Comment-section-reply-username {
  margin-top: 0.3em;
}

.Comment-section-comments .Askd-text-editor-uneditable {
  padding-top: 0.5em;
  padding-bottom: 0.2em;
  margin-top: 0.3em;
  border-top: 1px solid var(--super-dark-bg-color);
}

.Comment-section-timestamp {
  font-size: 0.8em;
  margin-left: 0.4em;
  color: gray;
}

.Comment-section-comment-buttons {
  margin-top: 0.1em;
}

.Comment-section-comment .icon-thumb_up_alt {
  position: relative;
  top: 1px;
  margin: 0.4em 0.3em 0.4em 0;
}

.Comment-section-reply-button {
  position: relative;
  right: 0.5em;
  padding: 0.4em 0.5em;
  border-radius: 2px;
  background: none;
}

.Comment-section-deleted {
  color: var(--bright-heading-color);
  margin-top: 0.4em;
  margin-bottom: 0.2em;
  font-size: 0.9em;
  font-style: italic;
}

.Comment-section-personal-buttons {
  position: absolute;
  bottom: 0.5em;
  right: 0.8em;
}

.Comment-section-personal-buttons button:first-child {
  margin-right: 1em;
}
