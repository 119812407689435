.Users-table {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.Users-table-search {
  margin-bottom: 0.5em;
}

.Users-table-search .Search-select {
  background-color: var(--main-content-color);
}

.Users-table-last-updated {
  font-style: italic;
  font-size: 0.8em;
  margin: 1.4em 0.2em 0.2em 0.2em;
}

.Users-table-table {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.Users-table-table-table {
  border-radius: 2px;
  overflow-x: auto;
}

.Users-table-table-table table {
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
  box-sizing: border-box;
  background-color: var(--main-content-color);
}

.Users-table-table-table th {
  color: var(--main-header-color);
  font-family: var(--bold-text-font);
}

.Users-table-table-table tr:nth-child(even),
.Users-table-table-table th {
  background-color: rgba(0, 0, 0, 0.01);
}

.Users-table-table-table tbody tr:hover {
  background-color: var(--main-bg-color);
}

.Users-table-table-table td, .Users-table-table-table th {
  padding: 0.4em;
  border: 1px solid var(--super-dark-bg-color);
  margin: 0;
  box-sizing: border-box;
}

.Users-table-table-table td:not(.Users-table-username),
.Users-table-table-table th:not(.Users-table-username) {
  text-align: center;
}

.Users-table-contributor:before {
  position: relative;
  top: 2.2px;
  margin-right: 0.1em;
  font-family: 'icomoon';
  content: "\e901";
}

.Users-table-contributor {
  color: var(--contributor-green);
  font-weight: bold;
}

.Users-table-username {
  width: 100%;
  max-width: 80%;
}

.Users-table-contribution {
  min-width: 8em;
}
