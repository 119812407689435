.Paginator {
  display: flex;
  flex: 0 1 auto;
}

.Paginator-list {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
}

.Paginator-list li {
  display: inline-block;
}

.Paginator-list button {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2.2em;
  min-width: 2.2em;
  border-top: 1px solid var(--darker-bg-color);
  border-bottom: 1px solid var(--darker-bg-color);
  border-right: 1px solid var(--darker-bg-color);
}

.Paginator-list li:first-child button {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 1px solid var(--darker-bg-color)
}

.Paginator-list li:last-child button {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Paginator-list-selected, .Paginator-list-free {
  border: none;
}

.Paginator-list-selected {
  color: white;
  background-color: var(--recent-actions-color);
  font-weight: bold;
}

.Paginator-list-free {
  background-color: var(--main-content-color);
  color: var(--username-black);
}

.Paginator-list-free:hover {
  cursor: pointer;
  background-color: var(--main-bg-color);
}

.Module-space .Paginator:first-child {
  margin-bottom: 0.4em;
}

.Module-space .Paginator:last-child, .Paginator-bottom {
  margin-top: 0.4em;
}
