.User-info {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  margin-bottom: 1em;
  overflow-wrap: break-word;
}

.User-info-avatar-outer {
  flex: 1 1 30%;
  max-width: 14em;
  align-self: flex-start;
}

.User-info-avatar {
  position: relative;
  padding-top: 100%;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: var(--main-content-shadow);
  border-radius: 3px;
}

.User-info-avatar-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #333333;
}

.User-info-avatar-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.User-info-avatar-box .Loading-spinner {
  width: 100%;
  height: 100%;
}

.User-info-avatar label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.User-info-avatar label:hover {
  opacity: 1;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}

.User-info-avatar input {
  display: none;
}

.User-info-info {
  flex: 1 1 auto;
  max-width: 70%;
  margin: 0;
  padding: 0.5em 0.5em 0 0.25em;
  box-sizing: border-box;
  font-family: var(--bold-text-font);
  color: var(--username-black);
  list-style-type: none;
}

.User-info-info li {
  margin: 0 0 0.8em 0;
  font-size: 1.2em;
}

.User-info-info li:last-child {
  margin: 0;
}

.User-info-info li span:first-child:not(.icon-envelope) {
  position: relative;
  top: 1.5px;
  margin-right: 0.3em;
}

.User-info-info .icon-envelope {
  position: relative;
  left: 1px;
  bottom: 0.5px;
  font-size: 0.8em;
  margin-right: 0.6em;
}

.User-info-info .Users-table-contributor {
  position: relative;
  bottom: 1px;
  font-size: 0.9em;
}

.User-info-info-contribution span:last-child:not(.Users-table-contributor) {
  margin-left: 0.18em;
}

.User-info-info-error {
  color: #D51530;
  font-size: 0.8em;
}

.User-info-info .Users-table-contributor {
  margin-left: 0.3em;
}
