.Header, .Header-small {
  display: flex;
  position: relative;
  font-family: var(--header-text-font);
  background-color: var(--main-header-color);
  width: 100%;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
}

.Header {
  height: 12em;
  transition: ease-out 0.5s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.Header-small {
  height: 3em;
  transition: ease-out 0.5s;
  flex: 0 0 auto;
}

.Header::before, .Header-small::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
  background-image: url('../img/header_bg.jpg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000020;
  transition: ease-out 0.5s;
}

.Header::before {
  height: 12em;
}

.Header-small::before {
  height: 3em;
}

.Header h1 {
  font-size: 3em;
  color: white;
  margin: 0;
  padding-bottom: 0.5em;
  z-index: 1;
  user-select: none;
}

.Header ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.Header-small ul {
  list-style-type: none;
  z-index: 1;
  margin: 0;
  font-size: 1em;
  padding: 1em;
}

.Header ul li, .Header-small ul li {
  display: inline;
  margin-right: 1.3em;
}

.Header-small ul li a, .Header ul li a {
  display: inline-block;
  color: white;
  transition: 0.25s;
  text-decoration: none;
}

.Header-small ul li a:hover,
.Header ul li a:hover,
.Header-top-right button:hover {
  cursor: pointer;
  color: #CCCCCC;
  transition: 0.25s;
}

.Header-divider::after {
  margin: 0.4em;
  color: #CC2277;
  content: '|';
}

.Header-top-right button {
  display: inline-block;
  transition: 0.25s;
  padding: 0;
  margin: 0;
}

.Header-top-right {
  position: absolute;
  top: 1em;
  right: 1em;
}

.Header-user-menu {
  position: absolute;
  top: 2.7em;
  right: 1em;
  z-index: 2;
}

.Header-bottom {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 255, 0.25);
  width: 100%;
  height: 0.1em;
}

button.Header-menu-button {
  color: white;
  background: none;
  border: none;
  font-family: var(--header-text-font);
  font-size: 1em;
}

button.Header-menu-button:hover {
  cursor: pointer;
}
