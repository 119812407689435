.Askd-text-editor {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  margin: 1em 0;
  box-sizing: border-box;
  font-weight: normal;
}

.Askd-text-editor-toolbar {
  background-color: var(--main-bg-color);
  height: 2.1em;
  width: 100%;
  border: 1px solid var(--super-dark-bg-color);
  border-bottom: none;
  box-sizing: border-box;
}

.Askd-text-editor-toolbar ul {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.Askd-text-editor-toolbar li {
  height: 1.6em;
  width: 1.6em;
  margin: 0 0 0 0.4em;
}

.Askd-text-editor-text {
  width: 100%;
  box-sizing: border-box;
  color: black;
  font-family: Arial;
  overflow: hidden;
}

.Askd-text-editor .Askd-text-editor-text {
  min-height: 25em;
}

.Askd-text-editor-text div {
  display: inline;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  hyphens: auto;
}

.Askd-text-editor .Askd-text-editor-uneditable {
  border-bottom: 1px solid var(--super-dark-bg-color);
  border-top: 1px solid var(--super-dark-bg-color);
  padding: 1em 0;
}

.Askd-text-editor-editable {
  padding: 0.7em 0.7em 8em 0.7em;
  border: 1px solid var(--super-dark-bg-color);
}

.Askd-text-editor-editable:focus {
  outline: none;
  border: 1px solid var(--recent-actions-color);
  box-shadow: inset 0 0 1px 0 var(--recent-actions-color);
  transition: border 0.3s, box-shadow 0.3s;
}

/* Toolbar icon styles */
.Askd-tb-selected-true button,
.Askd-tb-selected-true button:hover {
  color: var(--recent-actions-color);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  transition: background-color 0.1s;
}

.Askd-tb-icon {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 1.05em;
  font-family: 'icomoon';
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  transition: background-color 0.1s;
}

.Askd-tb-icon:hover {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.025);
  cursor: pointer;
  transition: background-color 0.1s;
}

.Askd-tb-MATH::after {
  content: "\f12b";
}

.Askd-tb-BOLD::after {
  content: "\ea62";
}

.Askd-tb-ITALIC::after {
  content: "\ea64";
  font-size: 0.96em;
}

.Askd-tb-STRIKETHROUGH:after {
  content: "\ea65";
  font-size: 0.96em;
}

.Askd-tb-UNDERLINE::after {
  position: relative;
  top: 1.1px;
  content: "\ea63";
}

.Askd-tb-IMAGE::after {
  content: "\f03e";
  font-size: 0.92em;
}

/* User-inputted text styles */
.Askd-te-MATH {
  color: green;
  padding: 0.15em;
  position: relative;
  font-family: Courier New, Consolas, Monaco, Lucida Console;
  font-size: 0.9em;
  bottom: 0.08em;
  background-color: rgba(0, 255, 0, 0.2);
}

.Askd-text-editor-editable .Askd-te-MATHJAX:hover {
  cursor: pointer;
}

.Askd-te-BOLD {
  font-weight: bold;
}

.Askd-te-ITALIC {
  font-style: italic;
}

.Askd-te-UNDERLINE {
  text-decoration: underline;
}

.Askd-te-STRIKETHROUGH {
  text-decoration: line-through;
}

.Askd-text-editor-text .Askd-te-IMAGE {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  margin: auto;
  color: rgba(0, 0, 0, 0);
  user-select: none;
}

.Askd-text-editor-editable .Askd-te-IMAGE:hover {
  cursor: pointer;
}

.Askd-text-editor-text .Askd-te-IMAGE .Askd-te-IMAGE-toolbar:hover {
  cursor: default;
}

.Askd-te-IMAGE-ignore {
  position: absolute;
}

.Askd-te-IMAGE img {
  width: 100%;
}

.Askd-te-IMAGE .Askd-te-IMAGE-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-text-color);
  height: 5em;
  font-size: 3em;
  border: 1px solid var(--super-dark-bg-color);
  border-radius: 2px;
}

.Askd-te-IMAGE-focused {
  box-shadow: var(--input-focused-shadow);
}

.Askd-te-IMAGE-blue {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--recent-actions-alpha);
}

.Askd-te-IMAGE-toolbar {
  position: absolute;
  width: 100%;
  min-width: 14em;
  bottom: -8em;
  left: calc((100% - max(100%, 14em))/2);
  box-sizing: border-box;
  padding: 0.6em 0.8em 0.8em 0.8em;
  color: var(--main-text-color);
  background-color: var(--main-content-color);
  box-shadow: var(--main-content-shadow);
  border-radius: 2px;
  z-index: 1;
}

.Askd-te-IMAGE .Askd-te-IMAGE-toolbar label {
  font-family: var(--main-text-font);
  margin-top: 0;
}

.Askd-te-IMAGE .Askd-te-IMAGE-toolbar label[for="Askd-te-IMAGE-size"] {
  margin-top: 0.3em;
}

.Askd-text-editor-demo {
  margin: 0.2em 0;
}

.Askd-text-editor-demo,
.Askd-text-editor-demo .Askd-text-editor {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.Askd-text-editor-demo .Askd-text-editor {
  margin: 0;
}

.Askd-text-editor-demo .Askd-text-editor-text {
  height: 100%;
}
