.Edit-note-contest {
  font-style: italic;
}

.Edit-note-form {
  display: flex;
  flex-direction: column;
  padding: 1.2em;
  margin-top: 0.7em;
  box-sizing: border-box;
  flex: 1 0 auto;
}

.Edit-note-title {
  margin-bottom: 1em;
}

.Edit-note-form .Askd-text-editor {
  flex: 1 0 auto;
}

.Edit-note-form .Askd-text-editor .Askd-text-editor-text {
  height: 100%;
}

.Edit-note-published-link {
  margin-top: -1em;
  margin-bottom: 1em;
}

.Edit-note-saved-text {
  font-size: 0.8em;
  font-style: italic;
  margin: 0 0 0.5em 0;
}

.Edit-note-bottom-buttons {
  display: inline-block;
}

.Edit-note-bottom-buttons input {
  margin-right: 1em;
  width: 10em;
  height: 2.2em;
}

.Edit-note-delete-menu {
  width: 80%;
  box-sizing: border-box;
  max-width: 35em;
}

.Edit-note-delete-menu label {
  user-select: none;
}
