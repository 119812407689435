.User-notes {
  overflow: auto;
  position: relative;
  min-height: 18em;
}

.User-notes-outer {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-top: 0.7em;
}

.User-notes-nothing {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  font-family: var(--main-text-font);
  font-weight: bold;
}

.User-notes-add {
  position: absolute;
  right: 0.3em;
  bottom: 0.3em;
  opacity: 0.6;
  background-color: var(--username-black);
  color: var(--main-content-color);
}

.User-notes-add:hover {
  opacity: 1;
  transition: opacity 0.5s;
}

.User-notes-organize {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 0.2em;
  color: var(--username-black);
}

.User-notes-organize:first-child {
  margin-top: -0.2em;
}

.User-notes-organize:last-child {
  margin: 0 0 0.5em 0;
  padding-bottom: 0.8em;
  border-bottom: 2px solid var(--darker-bg-color);
}

.User-notes-organize label {
  margin-right: 0.4em;
}

.User-notes-organize input[type="checkbox"] {
  margin: 0 0.25em 0 0.1em;
}

.User-notes-list {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  box-shadow: var(--main-content-shadow);
  box-sizing: border-box;
  background-color: var(--main-content-color);
}

.User-note-info {
  position: relative;
  border-bottom: 1px solid var(--super-dark-bg-color);
  padding: 0.5em;
  box-sizing: border-box;
}

.User-note-info:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.015)
}

.User-note-info h5 {
  margin: 0;
}

.User-note-info-display-link {
  color: var(--recent-actions-color-dark);
  text-decoration: none;
}

.User-note-info-display-link:hover {
  text-decoration: underline;
}

.User-note-info-title {
  color: gray;
  margin: 0.15em 0;
  font-weight: normal;
  font-style: italic;
  font-size: 0.7em;
}

.User-note-info-timestamp {
  margin: 0;
}

@media screen and (min-width: 500px) {
  .User-note-info-timestamp {
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
  }
}

@keyframes slowly-enable-pointer {
  from { pointer-events: none; }
  to { pointer-events: all; }
}

.User-note-info-links {
  position: absolute;
  padding: 0;
  top: 0.05em;
  right: 0.6em;
  list-style-type: none;
}

.User-note-info-links li {
  display: inline;
  text-align: right;
  font-size: 0.8em;
  margin-left: 1em;
}

.User-note-info:hover {
  background-color: var(--main-bg-color);
}

.User-note-info-tags {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin-top: 0.2em;
}

.User-note-info-tags li {
  margin-right: 1em;
}

.User-note-info-tag {
  display: inline-block;
  font-size: 0.6em;
  padding: 0.5em;
  border-radius: 4px;
  vertical-align: middle;
  color: var(--main-content-color);
}

.User-note-tag-published {
  background-color: var(--recent-actions-color);
}

.User-note-tag-published::before {
  content: 'Published!'
}

.User-note-tag-unpublished {
  background-color: var(--bright-heading-color);
}

.User-note-tag-unpublished::before {
  content: 'Unpublished'
}

.User-note-info-solved .User-note-solved-indicator {
  background-color: var(--contributor-green);
}

.User-note-info-solved .User-note-solved-indicator::before {
  content: 'Solved!';
}

.User-note-info-upsolved .User-note-solved-indicator {
  background-color: var(--upsolved-tag-color);
}

.User-note-info-upsolved .User-note-solved-indicator::before {
  content: 'Upsolved';
}

.User-note-info-upsolved-help .User-note-solved-indicator {
  background-color: var(--upsolved-help-tag-color);
}

.User-note-info-upsolved-help .User-note-solved-indicator::before {
  content: 'Upsolved with help';
}

.User-note-info-unsolved .User-note-solved-indicator {
  background-color: var(--unsolved-tag-color);
}

.User-note-info-unsolved .User-note-solved-indicator::before {
  content: 'Unsolved';
}

.User-note-contest-notes {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--darker-bg-color);
  margin-top: 0.2em;
  padding: 0;
  border-radius: 2px;
}

.User-note-contest-note {
  position: relative;
  display: flex;
  margin: 0.3em;
  width: 2.5em;
  height: 2.5em;
  box-shadow: var(--main-content-shadow);
  justify-content: center;
  align-items: center;
  color: var(--main-content-color);
}

.User-note-contest-timestamp {
  margin: 0;
}

.User-note-contest-code {
  font-size: 0.8em;
}

.User-note-contest-code-small {
  font-size: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.User-note-contest-links {
  position: absolute;
  padding: 0;
  top: 0;
  right: 0.2em;
  list-style-type: none;
}

.User-note-contest-links li {
  font-size: 0.6em;
  opacity: 0;
  transition: opacity 0.25s;
  text-align: right;
}

.User-note-contest-notes li:hover .User-note-contest-links li {
  opacity: 1;
  transition: opacity 0.25s;
  animation-name: slowly-enable-pointer;
  animation-duration: 0.4s
}

.User-note-contest-solved {
  background-color: var(--contributor-green);
}

.User-note-contest-upsolved {
  background-color: var(--upsolved-tag-color);
}

.User-note-contest-upsolved-help {
  background-color: var(--upsolved-help-tag-color);
}

.User-note-contest-unsolved {
  background-color: var(--unsolved-tag-color);
}

.User-notes-title-dropdown {
  position: relative;
  margin: 0 0.8em 0.8em 0.8em;
  box-sizing: border-box;
  border-radius: 4px;
}

.User-notes-title-dropdown:first-child {
  margin-top: 0.8em;
}

.User-notes-title-dropdown-showhide {
  position: absolute;
  top: 0.5em;
  right: 1em;
  border: none;
  background: none;
  padding: 0;
  color: var(--main-content-color);
}

.User-notes-title-dropdown-showhide:hover {
  cursor: pointer;
  text-decoration: underline;
}

.User-notes-title-dropdown-heading {
  padding: 0.5em;
  margin: 0;
  color: var(--main-content-color);
}

.User-notes-title-dropdown-heading-default {
  background-color: var(--recent-actions-color);
  box-shadow: 0 0 4px 0 var(--recent-actions-alpha);
}

.User-notes-title-dropdown-heading-solved {
  background-color: var(--contributor-green);
  box-shadow: 0 0 4px 0 var(--contributor-green-alpha);
}

.User-notes-title-dropdown-heading-upsolved {
  background-color: var(--upsolved-tag-color);
  box-shadow: 0 0 4px 0 var(--upsolved-tag-alpha);
}

.User-notes-title-dropdown-heading-upsolved-help {
  background-color: var(--upsolved-help-tag-color);
  box-shadow: 0 0 4px 0 var(--upsolved-help-tag-alpha);
}

.User-notes-title-dropdown-heading-unsolved {
  background-color: var(--unsolved-tag-color);
  box-shadow: 0 0 4px 0 var(--unsolved-tag-alpha);
}

.User-notes-outer .Paginator-list-free {
  background-color: var(--lighter-content-color);
}

.User-notes-outer .Paginator-list-free:hover {
  background-color: var(--main-bg-color);
}
