@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

html {
  --main-bg-color: #E8EAED;
  --darker-bg-color: #E6E2E4;
  --lighter-bg-color: #EDEFF0;
  --super-dark-bg-color: #D8DCDE;
  --main-content-color: #F0F2F5;
  --lighter-content-color: #F4F7FA;
  --main-text-color: black;
  --bright-heading-color: #FF5A5F;
  --main-header-color: #4C4B63;
  --recent-actions-color: #608FE6;
  --recent-actions-color-light: #729CE9;
  --recent-actions-color-dark: #001067;
  --recent-actions-alpha: rgba(96, 143, 230, 0.6);
  --username-black: #2C2B43;
  --username-gray: #65686B;
  --username-cyan: #03A89E;
  --username-blue: blue;
  --username-dark-orange: #F57545;
  --contributor-green: #35C585;
  --contributor-green-alpha: rgba(53, 197, 133, 0.6);
  --unsolved-tag-color: #E52540;
  --unsolved-tag-alpha: rgba(229, 37, 64, 0.6);
  --upsolved-tag-color: #8545E5;
  --upsolved-tag-alpha: rgba(133, 69, 229, 0.6);
  --upsolved-help-tag-color: #E5A575;
  --upsolved-help-tag-alpha: rgba(229, 165, 117, 0.6);
  --main-text-font: 'PT Sans Regular';
  --header-text-font: 'Mosk Medium 500';
  --bold-text-font: 'Mosk Bold 700';
  --main-content-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  --highlighted-content-shadow: 0 0 2px 0px rgba(255, 90, 95, 0.8);
  --input-focused-shadow: 0 0 1px 1px var(--recent-actions-alpha);
}

html {
  overflow-y: scroll;
}

html, body {
  background-color: var(--main-bg-color);
  font-family: var(--main-text-font);
  color: var(--main-text-color);
  margin: 0;
  width: 100%;
  height: 100%;
}

.Askd-form :focus:not(button):not(.Askd-text-editor-text):not(input[type="range"]) {
  box-shadow: var(--input-focused-shadow);
  outline: none;
  transition: box-shadow 0.3s;
}

button:focus, a:focus {
  outline: 1px solid var(--bright-heading-color);
}

body.Global-mouse-click *:not(input):focus {
  outline: none;
}

.App, #root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
}

/* MathJax, why is there a tiny white square
in the bottom-left corner if I don't do this? */
.MJX_HoverRegion, .MJX_ToolTip, .MJX_LiveRegion {
  display: none !important;
}
