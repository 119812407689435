.Clipboard-copy {
  position: relative;
  background-color: var(--main-bg-color);
  padding: 0.5em;
  border: 1px solid var(--super-dark-bg-color);
  text-overflow: ellipsis;
  overflow: hidden;
}

.Clipboard-copy:hover {
  cursor: pointer;
}

.Clipboard-copy div {
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  align-items: center;
  justify-content: center;
  transition: opacity 0.35s ease-out;
}

.Clipboard-copy .Clipboard-copy-clicked {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
