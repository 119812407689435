.Askd-form {
  width: 100%;
}

.Askd-form input,
.Askd-form textarea,
.Askd-form label,
.Askd-form select,
.Askd-form-button {
  display: block;
  border-radius: 3px;
  border: none;
  font-family: var(--main-text-font);
}

.Askd-form input::placeholder {
  color: var(super-dark-bg-color);
}

.Askd-form input:not(.Askd-not-fullwidth),
.Askd-form textarea,
.Askd-form label,
.Askd-form select {
  width: 100%;
}

input.Askd-not-fullwidth {
  display: inline-block;
}

.Askd-form label {
  margin-top: 1em;
}

.Askd-form textarea {
  min-height: 10em;
  resize: vertical;
}

.Askd-form select {
  background-image: url('../../img/dropdown.png');
  background-size: auto 70%;
  background-repeat: no-repeat;
  background-position: right 0.5em bottom 50%;
}

.Askd-form input:not(input[type="range"]), .Askd-form textarea, .Askd-form select,
.Askd-form-button {
  box-sizing: border-box;
  padding: 0.5em;
  border: 1px solid var(--super-dark-bg-color);
  appearance: none;
  line-height: 1.3;
  height: 2.4em;
}

.Askd-form input:enabled,
.Askd-form textarea:enabled,
.Askd-form select:enabled {
  background-color: rgba(0, 0, 0, 0);
}

.Askd-form :disabled {
  background-color: rgba(0, 0, 0, 0.03);
  transition: background-color 0.15s ease-out;
}

.Askd-form input[type="submit"]:enabled,
.Askd-form input[type="button"]:enabled,
.Askd-form-button {
  background-color: var(--recent-actions-color);
  color: var(--main-bg-color);
  transition: background-color 0.15s ease-out;
}

.Askd-form input[type="submit"]:hover:enabled,
.Askd-form input[type="button"]:hover:enabled,
.Askd-form-button:hover {
  background-color: var(--recent-actions-color-light);
  transition: background-color 0.15s ease-out;
}

a.Askd-form-button {
  text-decoration: none;
}

.Askd-form .Search-select {
  position: relative;
}

.Askd-form .Search-select-options {
  width: 100%;
}

.Askd-form-inline {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.8em;
}

.Askd-form-inline span {
  margin-right: 0.4em;
}

.Askd-form-inline input {
  margin-bottom: 0;
}

.Askd-form .Askd-form-link {
  font-size: 0.8em;
}

.Askd-form-link {
  font-weight: normal;
  color: var(--recent-actions-color);
  text-align: left;
  text-decoration: none;
}

.Askd-form-link:disabled {
  color: darkgray;
}

button.Askd-form-link {
  background: none;
  border: none;
  padding: 0;
}

.Askd-form-link:hover:not(:disabled) {
  cursor: pointer;
  text-decoration: underline;
}

.Askd-form-link-bottom {
  margin-top: 1em;
}

.Askd-button {
  border: none;
  transition: background-color 0.15s ease-out, opacity 0.2s;
}

.Askd-button-circular {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  height: 3.5em;
  width: 3.5em;
  margin: 0;
  border-radius: 100%;
}

.Askd-button:hover:enabled {
  cursor: pointer;
  transition: color, background-color, opacity 0.5s;
}

.Askd-button-generic {
  width: auto;
  border: none;
  transition: 0.5s;
  background: none;
  text-decoration: none;
  color: white;
  font-size: 1em;
}

.Askd-button-generic:hover {
  cursor: pointer;
  transition: color 0.25s;
  color: #CCCCCC;
}

.Askd-form-loading {
  background-image: url('../../img/spinner.gif');
  background-size: auto 60%;
  background-position: right 0.5em bottom 50%;
  background-repeat: no-repeat;
}

.Askd-form-required::after {
  color: #EE3333;
  font-size: 0.7em;
  font-style: italic;
  content: ' required';
}

.Askd-form-close {
  position: fixed;
  top: 1em;
  right: 1.5em;
  background: var(--main-content-color);
}

.Askd-form-close::before {
  font-family: 'icomoon';
  font-size: 1.5em;
  content: "\e903";
}

.Askd-form-close:hover {
  background-color: var(--super-dark-bg-color);
}
