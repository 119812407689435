@keyframes fade-dark {
  from { background-color: rgba(0, 0, 0, 0) }
  to { background-color: rgba(0, 0, 0, 0.2) }
}

.Loading-spinner {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  background-image: url('../../img/spinner.gif');
  background-blend-mode: darken;
  background-size: 2em;
  background-position: center;
  background-repeat: no-repeat;
}
