.Module-wrapper {
  display: flex;
  margin: auto;
  width: 85%;
  box-sizing: border-box;
  background-color: var(--lighter-bg-color);
  flex: 1 0 auto;
  overflow: hidden;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1100px) {
  .Module-wrapper {
    width: 94%;
  }
}

@media screen and (max-width: 900px) {
  .Module-wrapper {
    width: 100%;
  }
}

.Module-content-left {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 77%;
  box-sizing: border-box;
  padding: 0.7em;
  overflow: visible;
}

.Module-recent-notes {
  display: flex;
  flex-direction: column;
  flex: 0 1 23%;
  background-color: var(--main-content-color);
  box-sizing: border-box;
  box-shadow: var(--main-content-shadow);
  min-height: 35em;
  font-size: 0.8em;
}

.Module-recent-notes .icon-long-arrow-right {
  font-size: 0.8em;
  color: var(--username-black);
  margin: 0 0.2em;
}

.Module-recent-notes .icon-comments {
  position: relative;
  left: 1px;
}

.Module-recent-notes .icon-note-text,
.Module-recent-notes .icon-comments {
  font-size: 0.9em;
  color: var(--bright-heading-color);
}

.Module-recent-notes span:before {
  position: relative;
  top: 1px;
}

.Module-recent-notes .Loading-spinner {
  max-height: 100vh;
}

@media screen and (max-width: 1000px) {
  .Module-content-left {
    width: 75%;
    padding: 0.5em;
  }

  .Module-recent-notes {
    width: 25%;
  }
}

@media screen and (max-width: 727px) {
  .Module.content-left {
    width: 100%;
    padding: 0.4em;
  }

  .Module-recent-notes {
    display: none;
  }
}

.Module-recent-actions {
  background-color: var(--recent-actions-color);
  padding: 0.4em 0.6em;
}

.Module-recent-actions h4 {
  color: white;
  font-family: var(--main-text-font);
  font-weight: bold;
  font-size: 1.1em;
  padding: 0;
  margin: 0;
}

.Module-recent-notes-divider {
  width: 100%;
  background-color: var(--main-content-color);
  height: 0.1em;
}

.Module-recent-notes-list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow: auto;
}

.Module-recent-notes-list ol {
  position: absolute;
  padding: 0.2em 0.6em;
  list-style-type: none;
  margin: 0;
}

.Module-recent-notes-list li {
  white-space: pre-wrap;
  margin: 0.5em 0;
}

.Module-recent-notes-list li:first-child {
  margin-top: 0.2em;
}

.Module-recent-notes-list::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 0.5em;
}

.Module-recent-notes-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

.Module-recent-notes-list:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}

.Module-heading {
  margin: 0 0 0.3em 0;
  padding-bottom: 0.2em;
  border-bottom: 2px solid var(--darker-bg-color);
}

.Module-heading [class^="icon-"]:first-child,
.Module-heading [class*=" icon-"]:first-child {
  position: relative;
  top: 1.2px;
  margin-right: 0.28em;
}

.Module-heading:not(.Username) {
  color: var(--username-black);
}

.Module-paragraph {
  margin: 0 0 0.6em 0;
}

.Module-description {
  margin: auto;
  width: 85%;
  font-family: var(--main-text-font);
  font-weight: bold;
}

.Module-description-centered {
  text-align: center;
}

.Module-outer-space {
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: var(--main-content-shadow);
  background-color: var(--main-content-color);
}

.Module-outer-space .Module-heading:first-child {
  position: relative;
  top: -0.2em;
}

@media screen and (max-width: 750px) {
  .Module-outer-space {
    padding: 0.7em;
  }
}

.Module-space {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  box-sizing: border-box;
  overflow-wrap: anywhere;
  width: 100%;
  overflow: visible;
}

.Module-padding-box {
  padding: 1em;
}

.Module-space-heading, .Module-space-text {
  margin: 0.2em 0;
}

.Module-space-heading:first-child, .Module-space-text:first-child {
  margin: 0 0 0.2em 0;
}

.Module-space-text {
  font-size: 0.8em;
}

.Module-info-box h4 {
  margin: 0 0 0.2em 0;
}

.Module-info-box p, .Module-info-box a  {
  margin: 0.3em 0;
}

.Module-info-box a {
  font-size: 0.9em;
}

.Module-popup {
  background-color: var(--main-bg-color);
  color: var(--Username-black);
  display: flex;
  flex-direction: column;
  padding: 2em;
  margin: auto;
  border-radius: 3px;
  box-shadow: 0 1px 2px 1px var(--super-dark-bg-color);
}

.Module-popup h2, .Module-popup p {
  margin-bottom: 0;
}

.Module-popup h2 {
  position: relative;
  margin-top: -0.3em;
}

.Module-popup input[type="submit"] {
  margin-top: 2em;
}

.Module-popup .Module-popup-error,
.Module-popup .Module-popup-success {
  margin: -0.8em 0 1.2em 0;
  padding: 0.6em;
  color: white;
  border-radius: 2px;
  font-family: var(--main-text-font);
}

.Module-popup .Module-popup-error {
  background-color: var(--unsolved-tag-alpha);
}

.Module-popup .Module-popup-success {
  background-color: var(--contributor-green-alpha);
}

.Module-blocker {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  animation: fade-in 0.4s forwards;
}
