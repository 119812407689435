.Notes-search {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.Notes-search .Module-outer-space {
  padding: 1.2em;
  margin-top: 0.3em;
}

.Notes-search-sort {
  position: absolute;
  right: 0;
  top: -0.25em;
}

.Notes-search-sort label {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  margin-right: 0.4em;
}

.Notes-search label[for="username"] {
  margin: 0;
}

.Notes-search-reset {
  margin-top: 1em;
}

.Notes-search .Module-space {
  margin-top: 0.5em;
}
