.Public-note-solved-tag {
  position: relative;
  top: -0.08em;
  margin-right: 0.4em;
}

.Public-note-info {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-top: 0.7em;
}

.Public-note-info-edit {
  position: absolute;
  font-size: 0.9em;
  right: 0.8em;
  top: 0.3em;
}

.Public-note-info .Like-button {
  margin-top: 0.5em;
}
