.User-menu {
  position: absolute;
  right: 0;
  width: 9em;
  color: white;
  background-color: var(--main-header-color);
  border: 2px solid var(--super-dark-bg-color);
  box-shadow: var(--main-content-shadow);
}

.User-menu ol {
  list-style-type: none;
  margin: 0;
  padding: 0.3em 0;
}

.User-menu li button, .User-menu li a {
  width: 100%;
  color: white;
  display: block;
  text-decoration: none;
  text-align: left;
  box-sizing: border-box;
  padding: 0.3em 1em 0.3em 1em;
  transition: 0.25s;
}

.User-menu li:hover {
  cursor:pointer;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.25s;
}

.User-menu-divider {
  width: 100%;
  height: 2px;
  background-color: var(--super-dark-bg-color);
  margin: 0.3em 0;
}
