.Cp-notes-home-list {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Cp-notes-home-post {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  box-shadow: var(--main-content-shadow);
  margin-bottom: 0.7em;
}

.Cp-notes-home-post:last-child {
  margin-bottom: 0;
}

.Cp-notes-home-post .Module-paragraph,
.Cp-notes-home-post-image {
  margin: 1em 0;
}

.Cp-notes-home-post .Module-paragraph:first-child,
.Cp-notes-home-post-image:first-child {
  margin-top: 0;
}

.Cp-notes-home-post .Module-paragraph:last-child,
.Cp-notes-home-post-image:last-child {
  margin-bottom: 0;
}

.Cp-notes-home-post-date {
  position: absolute;
  right: 0.6em;
  top: 0.2em;
  font-size: 0.8em;
  color: var(--main-header-color);
}

.Cp-notes-home-post-image {
  margin: 1.3em 0;
}

.Cp-notes-home-post-image-area {
  display: flex;
  width: 100%;
  margin: auto;
}

.Cp-notes-home-post-image-area div {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 1em;
}

.Cp-notes-home-post-image-area img {
  width: 100%;
  border-radius: 2px;
  box-shadow: var(--highlighted-content-shadow);
}

.Cp-notes-home-post-image p:last-child {
  margin: 0.3em 0 0 0;
  text-align: center;
  font-style: italic;
  font-size: 0.9em;
  color: var(--username-black);
}

.Cp-notes-home-post-list {
  padding-left: 2em;
}

.Cp-notes-home-post-list li {
  padding-left: 0.3em;
}
