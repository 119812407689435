.Search-select-options {
  position: absolute;
  list-style-type: none;
  background-color: var(--main-content-color);
  border-radius: 2px;
  box-shadow: var(--main-content-shadow);
  padding: 0;
  margin: 0;
  max-height: 12em;
  overflow: auto;
  box-sizing: border-box;
  z-index: 1;
}

.Search-select-options li {
  font-size: 0.9em;
  font-family: var(--main-text-font);
  font-weight: bold;
  padding: 0.4em;
}

.Search-select-options li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
}
